import React, { useState, useEffect } from 'react';
import { notification } from '../../component/hocs/notification';
import moment from 'moment';
import Header from '../../component/layout/Header';
import Footer from '../../component/layout/Footer';
import Page from '../../component/layout/Page';

import axios from 'axios';
import { mainUrl } from '../../MainUrl';
import DataTable from 'react-data-table-component';

import {
    Row, Col, Card, CardHeader, CardBody, Button, Label, Input, FormGroup
} from 'reactstrap';
import ExcelExport from '../../component/utility/ExcelExport';


const DailyMilkTransaction = () => {

    const [loading, setLoading] = useState(false);

    const [transDate, setTransDate] = useState(moment().format('YYYY-MM-DD'));
    const [transList, setTransList] = useState('');
    const [excelData, setExcelData] = useState([]);

    const get_Transaction_list = () => {
        setLoading(true);

        let postData = { "to_date": transDate, }

        axios.post(mainUrl + 'mis/viewTransaction', postData)
            .then(function (response) {
                let totMilk = 0;
                let totPrice = 0;
                if (response.data.status === 1) {
                    let list = response.data.data.map((row, index) => {
                            totMilk = totMilk + Number(row.qty);
                            let tot = Number(Number(row.rate) * Number(row.qty)).toFixed(2);
                            totPrice = Number(totPrice) + Number(tot);
                        return {
                            sl: index + 1,
                            fpo_name: row.fpo_name,
                            farmer_name: row.farmer_name,
                            qty: <span>{row.qty} ltrs</span>,
                            rate: <span>&#8377; {Number(row.rate).toFixed(2)}</span>,
                            total: <span>&#8377; {Number(Number(row.rate) * Number(row.qty)).toFixed(2)}</span>,
                            buyer_name: row.buyer_name,
                            trans_date: row.trans_date.split(" ")[0].split('-').reverse().join('-'),
                        }
                    });
                    //
                    let temp = {
                        sl: '',
                        fpo_name: <b>Total</b>,
                        farmer_name: '',
                        qty: <b>{totMilk} ltrs</b>,
                        rate: '',
                        total: <b>&#8377; {Number(totPrice).toFixed(2)}</b>,
                        buyer_name: '',
                        trans_date:''
                    }
                    list.push(temp);
                    //
                    setTransList(list);
                    // Export Excel data
                    let e_list = response.data.data.map((row, index) => {
                        return {
                            "SL No.": index + 1,
                            "FPO Name": row.fpo_name,
                            "Farmer Name": row.farmer_name,
                            "Quantity (In Liters)": row.qty,
                            "Rate/Ltrs": Number(row.rate).toFixed(2),
                            "Total Amount": Number(Number(row.rate) * Number(row.qty)).toFixed(2),
                            "Buyer Name": row.buyer_name,
                            "Transaction Date": row.trans_date.split(" ")[0].split('-').reverse().join('-'),
                        }
                    });
                    setExcelData(e_list);
                }
                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
                //let notify = notification({ message: "User list not Found! Try Again", type: 'error' });
                //notify();
            });
    }

    return (
        <div>
            <Header />
            <Page>
                <Row>
                    <Col xs={12}>
                        <h4 className='text-primary mb-2'>View Transaction</h4>
                        <Card className='mb-3' color="primary" outline>
                            <CardBody>
                                <FormGroup row>
                                    <Label md={1} for="date"> Transaction Date </Label>
                                    <Col md={3}>
                                        <Input type="date" value={transDate} onChange={(e) => setTransDate(e.target.value)} required />
                                    </Col>
                                    <Col md={3}>
                                        <Button color='primary' size="sm" onClick={get_Transaction_list}> Submit </Button>
                                    </Col>
                                    <Col md={5} className='text-end'>
                                        {
                                            (excelData && excelData.length > 0) &&
                                            <ExcelExport data={excelData} name="milk_transaction" />
                                        }
                                    </Col>
                                </FormGroup>
                            </CardBody>
                        </Card>
                        <Card color="primary" outline>
                            <CardHeader>Milk Transaction List</CardHeader>
                            <CardBody>
                                <DataTable
                                    title=""
                                    columns={[
                                        {
                                            name: '#',
                                            selector: row => row.sl,
                                            sortable: true,
                                            width: "80px"
                                        },
                                        {
                                            name: 'FPO Name',
                                            selector: row => row.fpo_name,
                                            sortable: false,
                                        },
                                        {
                                            name: 'Farmer Name',
                                            selector: row => row.farmer_name,
                                            sortable: false,
                                        },
                                        {
                                            name: 'Quantity',
                                            selector: row => row.qty,
                                            sortable: false,
                                        },
                                        {
                                            name: 'Rate/ltr',
                                            selector: row => row.rate,
                                            sortable: false,
                                        },
                                        {
                                            name: 'Total',
                                            selector: row => row.total,
                                            sortable: false,
                                        },
                                        {
                                            name: 'Buyer Name',
                                            selector: row => row.buyer_name,
                                            sortable: false,
                                        },
                                        {
                                            name: 'Transaction Date',
                                            selector: row => row.trans_date,
                                            sortable: false,
                                        },
                                    ]}
                                    data={transList}
                                    striped
                                    highlightOnHover
                                    progressPending={loading}

                                />
                            </CardBody>
                        </Card>
                    </Col>

                </Row>
            </Page>
            <Footer />
        </div>
    )
}

export default DailyMilkTransaction;